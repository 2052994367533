
























import Vue from "vue";
import content from "@/content.json";

export default Vue.extend({
  name: "WelkomComponent",
  data: () => ({
    page: content.pages.welkom,
    links: content.links,
  }),
});
